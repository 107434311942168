<template>
  <v-app-bar app clipped-left color="#F0C316" >
    <span @click="$router.push('/')"
      class="pl-2 mr-2 font-weight-light pointer"
      v-if="!$vuetify.breakpoint.xsOnly"
      style="color: white; font-size: 25px;"
      > <span><v-icon color="white">mdi-minus</v-icon></span>
      smpl <span class="font-weight-black black--text">sale</span>
    </span>

    <v-app-bar-nav-icon @click.stop="drawerToggle" />

    <v-toolbar-title class="mr-12 align-center">
      <span class="title white--text text-capitalize">{{ currentBusiness.name }}</span>
    </v-toolbar-title>
    <!-- <v-col cols="3"  v-if="$vuetify.breakpoint.smAndUp">
      <AutoComplete v-model="selectedCustomer" />
    </v-col> -->

    <v-spacer />
    <UserMenu />
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import Notifications from "./Notifications";
import customerService from "@/modules/Customer/service.js";
// import AutoComplete from "@/modules/Customer/components/AutoComplete"
import UserMenu from "./Menu";
export default {
  data() {
    return {
      Customer: [],
      selectedCustomer: null,
      loading: false,
      customerSearch: null,
    };
  },
  watch: {
    selectedCustomer(val) {
      if (val) {
        this.$router.push({ path: `/customer/${val.id}/profile` });
      }
    }
  },
  components: {
    // AutoComplete,
    UserMenu,
  },
  created() {
    this.getCustomerList();
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"])
  },
  methods: {
    ...mapActions("layout", ["drawerToggle"]),
    getCustomerList() {
      this.loading = true;
      return customerService.getAll().then(response => {
        this.loading = false;
        this.Customer = response.data;
        return response;
      });
    }
  }
};
</script>

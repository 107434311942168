import backofficeService from '../../api/backoffice'

export default {
  getNotificationsByUser(id) {
    return backofficeService.Api().get(`api/getNotifications/ByUser/${id}`)
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  setNotification(data) {
    return backofficeService.Api().post('api/addNotification', data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  markAllRead(id) {
    return backofficeService.Api().post(`api/notification/markAllRead/${id}`)
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  markRead(id) {
    return backofficeService.Api().post(`api/notification/markReadByID/${id}`)
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  getRating(id) {
    return backofficeService.Api().get(`api/getAverageRating/${id}`)
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  passdueLeads(id) {
    return backofficeService.Api().get(`api/leads/passDue/${id}`)
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  passdueTickets(id) {
    return backofficeService.Api().get(`api/tickets/passDue/${id}`)
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  totalTaskCount(id) {
    return backofficeService.Api().get(`api/task/count/byUser/${id}`)
      .then(response => response.data)
      .catch((error) => { throw error })
  },
}

<template>
  <div class="text-center">
    <v-card>
      <v-card-text>
        <v-flex>
          <v-flex class="mb-3 display-2 font-weight-light">
            <span style="color: #50e3c2">smpl</span
            ><span class="font-weight-black" style="color: #383838">POS</span>
          </v-flex>
          <v-flex xs12>
            <span>Login Again</span>
          </v-flex>
          <GoogleLogin
            class="mt-3 mb-3"
            v-on:response="checkResponse"
            :code="code"
          ></GoogleLogin>

          <div class="mb-5">Or</div>
          <v-text-field
            v-model="code"
            outlined
            ref="locationCode"
            dense
            :error-messages="codeErrors"
            label="Code"
          ></v-text-field>
          <v-text-field
            v-model="email"
            outlined
            dense
            :error-messages="emailErrors"
            label="Email"
          >
          </v-text-field>
          <v-text-field
            v-model="password"
            outlined
            dense
            :append-icon="showPassword ? 'visibility_off' : 'visibility'"
            :type="showPassword ? 'text' : 'password'"
            :label="$t('labels.password')"
            v-on:keyup.enter="submit"
            :error-messages="passwordErrors"
            @input="$v.password.$touch()"
            counter
            @click:append="showPassword = !showPassword"
          >
          </v-text-field>
        </v-flex>
      </v-card-text>
      <v-card-actions>
        <v-layout>
          <v-spacer> </v-spacer>
          <v-btn @click="submit" color="primary">{{
            $t("buttons.login")
          }}</v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import authService from "../service";
import GoogleLogin from "./GoogleLogin";

const { validationMixin } = require("vuelidate");
const { required, email } = require("vuelidate/lib/validators");

export default {
  name: "authentication-login",
  data() {
    return {
      showPassword: false,
      email: null,
      password: null,
      code: null,
    };
  },
  components: {
    GoogleLogin,
  },
  created() {
    const localStoreCode = localStorage.getItem(
      `${process.env.VUE_APP_STORAGE_PREFIX}code`
    );
    if (localStoreCode) this.code = localStoreCode;
  },
  methods: {
    ...mapActions("global", ["initOrganization", "setLoginDialog"]),
    checkResponse(response) {
      if (response.status === 400) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: "Invalid User",
          });
      } else {
        this.setLoginDialog(false);
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
        this.$Progress.start();
        authService
          .authenticate({
            email: this.email,
            password: this.password,
            code: this.code,
          })
          .then((response) => {
            this.$Progress.finish();
            this.checkResponse(response);
            this.setLoginDialog(false);
          })
          .catch((error) => {
            this.$Progress.finish();
            console.log("error on ", error);
            this.$swal("Invalid Login", "Check email and password", "error");
          });
      }
    },
  },
  watch: {
    loginDialog(val) {
      if (val) {
        const localStoreCode = localStorage.getItem(
          `${process.env.VUE_APP_STORAGE_PREFIX}code`
        );
        if (localStoreCode) this.code = localStoreCode;
      }
    },
  },
  computed: {
    ...mapGetters("global", ["loginDialog"]),
    codeErrors() {
      const errors = [];
      if (!this.$v.code.$dirty) return errors;
      if (!this.$v.code.required) errors.push("Code is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      if (!this.$v.email.email) errors.push("Email is not valid");
      if (!this.$v.email.required) errors.push("Email is required.");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      if (!this.$v.password.required) errors.push("Password is required.");
      return errors;
    },
  },
  mixins: [validationMixin],
  validations: {
    email: {
      email,
      required,
    },
    code: {
      required,
    },
    password: {
      required,
    },
  },
};
</script>

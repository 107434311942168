<template>
  <v-flex class="ma-2 pointer">
    <img
      id="google-btn"
      height="50px"
      src="../../../assets/images/google_signin.png"
      v-on:mouseover="hover(this)"
      v-on:mouseout="unhover(this)"
      @click="init"
    />
  </v-flex>
</template>
<script>
import defaultImage from "@/assets/images/google_signin_hover.png";
import hoverImage from "@/assets/images/google_signin.png";
import authService from "../service.js";
export default {
  name: "google-login",
  props: ["code"],
  methods: {
    init() {
      if (!this.code) {
        this.$swal({
          title: "Google Login",
          text: "Please enter business code before login in with google",
          icon: "error",
        });
        return false;
      }
      this.$gAuth
        .getAuthCode()
        .then(async (authCode) => {
          //on success
          return authService.Google.getToken({ authCode }, { code: this.code });
        })
        .then((response) => {
          this.$emit("response", response);
        })
        .catch((error) => {
          console.log("error", error);
          if (error.status && error.status === 404) {
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "error",
                title: error.data.message,
              });

            if (error.data && error.data.payload) {
              const payload = error.data.payload;
              // We can still see the google data for their sign up
              this.$router.push({
                name: "signup",
                params: {
                  firstName: payload.given_name,
                  lastName: payload.family_name,
                  email: payload.email,
                  verified: payload.email_verified,
                },
              });
            } else {
              // this.$router.push("/signup");
              // this.$emit("response", error);
            }
          } else if (error.error) {
            // Do nothing
            // console.log("Pop up error most likely");
          } else {
            this.$emit("response", error);
          }
        });
    },
    hover() {
      document.getElementById("google-btn").setAttribute("src", defaultImage);
    },
    unhover() {
      document.getElementById("google-btn").setAttribute("src", hoverImage);
    },
  },
};
</script>

import backofficeService from '@/api/backoffice'

export default {
 
  addCard(data) {
    return backofficeService.Api().post('stripe/card/add', data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  createCustomer(data) {
    return backofficeService.Api().post('stripe/customer/create', data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },


  getCustomer(data) {
    return backofficeService.Api().post('stripe/customer/get', data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  changeDefaultCard(data) {
    return backofficeService.Api().post('stripe/customer/changeDefaultCard', data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  deleteCard(data) {
    return backofficeService.Api().post('stripe/card/delete', data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  getCards(data) {
    return backofficeService.Api().post('stripe/card/getAll', data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  getPlans(data) {
    return backofficeService.Api().post('stripe/plan/getAll', data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  getSubscriptionItems(data) {
    return backofficeService.Api().post('stripe/subscription/items', data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  getSubscription(data) {
    return backofficeService.Api().post('stripe/subscription/get', data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  cancelSubscrition(data) {
    return backofficeService.Api().post('stripe/subscription/cancel', data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  createSubscrition(data) {
    return backofficeService.Api().post('stripe/subscription/create', data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  changePlan(data) {
    return backofficeService.Api().post('stripe/plan/change', data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  getAllInvoice(data) {
    return backofficeService.Api().post('stripe/invoice/getAll', data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },


}

<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >

      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          Subscription Canceled
        </v-card-title>

        <v-card-text class="mt-3">
         It seem like you Subscription is cancled. it could be number of reson please visit account to fix it
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="gotoAccount"
          >
            goto account
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props: ['show'],
    data () {
      return {
        dialog: false,
      }
    },
    methods: {
      gotoAccount() {
        this.$emit('gotoAccount')
      }
    },
    watch: {
      show(val) {
        this.dialog = val
      }
    }
  }
</script>
/* eslint-disable vue/valid-v-for */
<template>
  <v-navigation-drawer v-model="$store.state.layout.drawer" clipped app>
    <v-list dense nav shaped>
      <template v-for="item in items">
        <v-row v-if="item.header" :key="item.header">
          <v-col cols="6">
            <div v-if="item.header" class="ml-2 caption">{{ item.header }}</div>
          </v-col>
          <v-col cols="6">
            <div class="text-right mr-2 caption">{{ version }}</div>
          </v-col>
        </v-row>
        <v-list-group v-else-if="item.children" :key="item.title" v-model="item.model"
          :prepend-icon="item.model ? item.icon : item['icon-alt']" append-icon="">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item :to="child.path" class="ml-5" @click="child.click && child.click()"
            v-for="(child, i) in item.children" :key="i" link>
            <v-list-item-action v-if="child.icon">
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ child.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-divider :key="item.name" v-else-if="item.divider"> </v-divider>
        <v-list-item v-else :key="item.title" link :to="item.path" @click="item.click && item.click()">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <ImeiSearch />
      <RestockSerial />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import dashbaordService from "../../Home/service";
// import inventoryService from "../../Inventory/service";
const ImeiSearch = () => import("@/components/ImeiSearch");
const RestockSerial = () => import("@/components/RestockSerial");
const pjson = require("../../../../package.json");
// import navService from "../service";
// import sound from "../../../assets/audio/u_have_a_task.mp3";

// const audio = new Audio(sound);

export default {
  data() {
    return {
      items: [],
      mini: false,
      right: null,
      leadsBadge: 0,
      ticketsBadge: 0,
      taskBadge: 0,
      inventoryBadge: 0,
      locationBadge: 0,
    };
  },
  computed: {
    ...mapGetters("integration", ["modules"]),
    ...mapGetters("global", [
      "currentUser",
      "checkRightStatus",
      "currentBusiness",
    ]),
    version() {
      return pjson.version;
    },
  },
  components: {
    ImeiSearch,
    RestockSerial,
  },
  async created() {
    await this.initUser();
    // console.log("currentBusinesscurrentBusiness", this.currentBusiness);
    await this.loadNav();
  },
  // watch: {
  //   currentBusiness() {
  //     this.loadNav();
  //   }
  // },
  methods: {
    ...mapActions("layout", ["setOpenSerialSearch", "setOpenRestockSerial"]),
    ...mapActions("global", [
      "setGlobalStatus",
      "setLoginDialog",
      "initUser",
      "initType",
    ]),
    loadNav() {
      this.items = [];
      this.items.push({ header: "Menu" });
      this.items.push({
        title: this.$t("sideBar.home"),
        icon: "mdi-home-outline",
        name: "home",
        path: "/",
      });
      this.items.push({ divider: true });
      this.items.push({
        title: "Customer",
        icon: "mdi-account-outline",
        name: "customer",
        path: "/customer",
      });

      this.items.push({
        title: "Inventory",
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        name: "inventory",
        path: "/inventory",
        children: this.getInventoryItem()
        // [
        // {
        //   name: "purchase",
        //   icon: "mdi-package-variant-closed",
        //   title: "Purchase",
        //   path: "/inventory/purchase",
        // },
        // {
        //   name: "purchaseOrder",
        //   icon: "mdi-package-variant",
        //   title: "Purchase Order",
        //   path: "/inventory/purchaseOrder",
        // },
        // {
        //   name: "vendor",
        //   icon: "mdi-truck-outline",
        //   title: "Vendor",
        //   path: "/inventory/vendor",
        // },
        // {
        //   name: "serail",
        //   icon: "mdi-barcode",
        //   title: "Serial",
        //   path: "/inventory/serial/list",
        // },
        // {
        //   name: "count",
        //   icon: "mdi-counter",
        //   title: "Count",
        //   path: "/inventory/count",
        // },
        // ],
      });
      if (this.checkRightStatus(67)) {
        this.items.push({
          title: "Order",
          icon: "mdi-book-open-outline",
          name: "order",
          path: "/order",
          // children: [
          //   {
          //     name: "inHouse",
          //     icon: "mdi-office-building",
          //     title: "In House",
          //     path: "/order",
          //   },
          //   {
          //     name: "catalog",
          //     icon: "mdi-book-open-outline",
          //     title: "Catalog",
          //     path: "/catalog/create",
          //   },
          //   {
          //     name: "remote",
          //     icon: "mdi-tablet-cellphone",
          //     title: "Remote",
          //     path: "/RemoteView",
          //   },
          // ],
        });
      }
      if (this.checkRightStatus(63)) {
        this.items.push({
          title: "Invoice",
          icon: "mdi-cart-outline",
          name: "invoice",
          path: "/invoice",
        });
      }

      if (this.checkRightStatus(61)) {
        this.items.push({
          title: "Leads",
          icon: "mdi-filter-outline",
          name: "leads",
          path: "/lead",
        });

      }

      if (this.checkRightStatus(62)) {

        this.items.push({
          title: "Product",
          icon: "mdi-cellphone-android",
          name: "product",
          path: "/product/list",
        });
      }

      if (this.currentBusiness.metadata && this.currentBusiness.metadata.workOrder) {
        this.items.push({
          title: "Work Order",
          icon: "mdi-book-open-outline",
          name: "workOrder",
          path: "/workOrder/list",
        });
      }
      // this.items.push({
      //   title: "Category",
      //   icon: "mdi-shape",
      //   name: "expense",
      //   path: "/inventory/category/list"
      // });
      if (this.checkRightStatus(66)) {
        this.items.push({
          title: "Expense",
          icon: "mdi-wallet-outline",
          name: "expense",
          path: "/expense",
        });
      }
      //console.log('this.checkRightStatus(51)', this.checkRightStatus(51))
      if (this.checkRightStatus(51)) {
        this.items.push({
          title: "Report",
          icon: "mdi-chart-bell-curve",
          name: "report",
          path: "/report/home",
        });
      }


      if (this.currentBusiness.metadata && this.currentBusiness.metadata.serializeProduct) {
        if (this.checkRightStatus(65)) {
          this.items.push({
            title: "Serial Search",
            icon: "mdi-feature-search-outline",
            name: "serailSearch",
            click: () => {
              this.setOpenSerialSearch(true);
            },
          });
        }
      }

      this.items.push({ divider: true });
      this.items.push({
        title: this.$t("sideBar.list"),
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        name: "list",
        path: "",
        children: this.getListItem()
      });


      if (this.checkRightStatus(64)) {

        this.items.push({
          title: "Deleted",
          icon: "mdi-chevron-up",
          "icon-alt": "mdi-chevron-down",
          name: "deleted",
          path: "",
          children: [
            {
              name: "deleteCustomer",
              icon: "mdi-account-off-outline",
              title: "Deleted Customer",
              path: "/customer/deleted",
            },
            {
              name: "deletedInvoice",
              title: "Deleted Invoice",
              icon: "mdi-receipt-text-remove-outline",
              path: "/invoice/deleted",
            },
            {
              name: "deletedPayment",
              title: "Deleted Payments",
              icon: "mdi-currency-usd-off",
              path: "/customer/payment/deleted",
            },
          ],
        });

      }


      this.items.push({
        title: "Admin Tools",
        icon: "mdi-account-key-outline",
        name: "adminTools",
        path: "/adminTools/home",
      });

      // this.items.push({
      //   title: this.$t("sideBar.adminTools"),
      //   group: "",
      //   icon: "mdi-chevron-up",
      //   "icon-alt": "mdi-chevron-down",
      //   children: [
      //     {
      //       name: "user",
      //       icon: "mdi-shield-account-outline",
      //       title: this.$t("sideBar.user"),
      //       path: "/user",
      //     },
      //     {
      //       name: "restock",
      //       icon: "mdi-barcode-scan",
      //       title: "Restock Serial",
      //       click: () => {
      //         this.setOpenRestockSerial(true);
      //       },
      //     },
      //     {
      //       name: "setting",
      //       icon: "mdi-cog-outline",
      //       title: "Setting",
      //       path: "/setting",
      //     },
      //   ],
      // });
    },
    getListItem() {
      let list = []
      if (this.checkRightStatus(70)) {
        list.push(
          {
            name: "payee",
            icon: "mdi-account-check-outline",
            title: "Payee",
            path: "/expense/payee/list",
          }
        )
      }
      if (this.checkRightStatus(68)) {
        list.push(
          {
            name: "vendor",
            icon: "mdi-truck-outline",
            title: "Vender",
            path: "/inventory/vendor",
          }
        )
      }

      if (this.checkRightStatus(69)) {
        list.push(
          {
            name: "payments",
            icon: "mdi-cash-multiple",
            title: "Payments",
            path: "/customer/payment/home",
          }
        )
      }

      return list
    },
    getInventoryItem() {
      let list = []
      if (this.checkRightStatus(38)) {
        list.push(
          {
            name: "purchase",
            icon: "mdi-package-variant-closed",
            title: "Purchase",
            path: "/inventory/purchase",
          }
        )
      }
      if (this.checkRightStatus(48)) {
        list.push(
          {
            name: "purchaseOrder",
            icon: "mdi-package-variant",
            title: "Purchase Order",
            path: "/inventory/purchaseOrder",
          },
        )
      }

      if (this.checkRightStatus(68)) {
        list.push(
          {
            name: "vendor",
            icon: "mdi-truck-outline",
            title: "Vendor",
            path: "/inventory/vendor",
          }
        )
      }
      // console.log('this.currentBusiness', this.currentBusiness)
      if (this.currentBusiness && this.currentBusiness.metadata && this.currentBusiness.metadata.serializeProduct) {
        if (this.checkRightStatus(71)) {
          list.push({
            name: "serail",
            icon: "mdi-barcode",
            title: "Serial",
            path: "/inventory/serial/list",
          })
        }
      }
      return list


    }
  },
};
</script>

<style lang="scss" scoped>
// @import "@/scss/variable.scss";

// .sidebar-nav {
//   padding: 8px 35px 8px 15px;
//   display: block;
//   font-size: 15px;
//   border-radius: 4px;
//   white-space: nowrap;
//   color: $sidebar-text;
// }

.v-chip--x-small {
  font-size: 10px;
  height: 16px;
}

.v-chip--x-small .v-chip__content {
  line-height: 1;
  padding: 8px;
}
</style>

<template>
  <div class="on-print">
    <v-dialog v-model="loginDialog" width="400">
      <LoginDialog />
    </v-dialog>

    <div v-if="!globalStatus" class="on-print">
      <toolbar />
      <navbar />
      <v-main>
        <CancelDialog :show="showCancel" @gotoAccount="gotoAccount" />
        <router-view></router-view>
      </v-main>
      <v-footer app v-if="!$vuetify.breakpoint.xsOnly">
        <span>Need Help Call: (347) 441-0407</span>
        <v-spacer></v-spacer>
        <div>
          smplsale.com &copy; {{ new Date().getFullYear() }} | V-{{ version }}
        </div>
      </v-footer>
    </div>
    <div v-else>
      <v-dialog v-model="globalStatus" hide-overlay persistent width="300">
        <v-card color="yellow lighten-1" class="pa-3" >
          <v-card-text color="text--black">
            {{ $t("labels.pleaseStandBy") }}
            <v-progress-linear
              indeterminate
              color="black"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <v-dialog v-model="loadingStatus.status" persistent width="300">
      <v-card color="yellow lighten-1" dark>
        <v-card-text>
          {{ loadingStatus.title }}
          <v-progress-linear
            indeterminate
            color="black"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RegisterStoreModule from "@/mixins/RegisterStoreModule";
import layoutStore from "./store";
import Navbar from "@/modules/Layout/components/NavigationPanel.vue";
import LoginDialog from "../Authentication/components/LoginDialog";
import Toolbar from "@/modules/Layout/components/Toolbar.vue";
import accountService from "@/modules/Account/service.js";
import backofficeService from "../../api/backoffice.js";
import CancelDialog from "@/modules/Layout/components/cancelDialog.vue";
import { mapGetters, mapActions } from "vuex";
const pjson = require("../../../package.json");

export default {
  name: "layout",
  mixins: [RegisterStoreModule],
  async created() {
    try {
      this.registerStoreModule("layout", layoutStore);
      await this.initUser();
      await this.initType();
      this.setGlobalStatus(false);
      this.pollData();
      if (this.currentBusiness.subscribable) this.checkSubcription();
      // // console.log('currentBusiness', this.currentBusiness)
    } catch (error) {
      this.$router.push("/logout");
    }
  },
  components: {
    Navbar,
    CancelDialog,
    Toolbar,
    LoginDialog,
  },
  // sockets: {
  //   connect: function() {
  //     // // console.log('socket connected')
  //     this.joinSocket();
  //   },
  //   message: function(data) {
  //     // // console.log('message', data)
  //   }
  // },
  computed: {
    ...mapGetters("global", [
      "currentUser",
      "currentBusiness",
      "globalStatus",
      "loadingStatus",
      "loginDialog",
    ]),
    version() {
      return pjson.version;
    },
  },
  props: {
    source: String,
  },
  // beforeDestroy() {
  //   clearInterval(this.polling);
  // },
  data() {
    return {
      subscriptionStatus: "active",
      showCancel: false,
      include: ["home", "customer-home"],
      authenticated: true,
      polling: null,
      Subscription: null,
    };
  },
  watch: {
    subscriptionStatus(val) {
      if (val === "canceled") {
        this.showCancel = true;
      }
    },
    authenticated(val) {
      if (!val) {
        this.$swal(
          "Session Expired",
          "Your token was expaired please login again.",
          "warning"
        ).then(() => {
          // this.$router.push("/login");
          this.setLoginDialog(true);
        });
      }
    },
  },
  methods: {
    ...mapActions("global", [
      "setGlobalStatus",
      "setLoginDialog",
      "initUser",
      "initType",
    ]),
    checkSubcription() {
      this.getSubscription();
    },
    gotoAccount() {
      this.showCancel = false;
      this.$router.push("/account");
    },
    getSubscription() {
      return accountService
        .getSubscription({
          subscription_id: this.currentBusiness.subscription_id,
        })
        .then((result) => {
          // console.log("asdfksdfsdf", result);
          this.Subscription = result.data;
          if (this.Subscription.status === "canceled")
            this.subscriptionStatus = "canceled";
        });
    },
    // sendSocket(data) {
    //   // // console.log('sendSocket', data)
    //   this.$socket.emit("userStatus", data);
    // },
    // joinSocket() {
    //   if (this.currentUser) {
    //     this.$socket.emit("join", this.currentUser);
    //   }
    // },
    async checkToken() {
      this.authenticated = await backofficeService.valid();
    },
    pollData() {
      this.polling = setInterval(() => {
        if (
          !localStorage.getItem(`${process.env.VUE_APP_STORAGE_PREFIX}token`)
        ) {
          this.authenticated = false;
        }
      }, 1000);
    },
  },
};
</script>

<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="250"
      transition="slide-y-transition"
      offset-y
    >
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon large="large">
          <v-badge avatar overlap color="black" v-if="currentUser.superAdmin || currentUser.isAdmin">
            <template v-slot:badge>
              <v-avatar >
                <v-icon small color="white" v-if="currentUser.superAdmin">mdi-crown</v-icon>
                <v-icon small color="white" v-else-if="currentUser.isAdmin">mdi-account-circle</v-icon>
              </v-avatar>
            </template>

            <v-avatar size="40">
              <v-img :src="currentUser.imageUrl" v-if="currentUser.imageUrl"></v-img>
              <img v-else src="@/assets/images/user.jpeg" alt="User" />
            </v-avatar>
          </v-badge>


          <v-avatar size="40px" v-else>
            <img
              v-if="currentUser.imageUrl"
              :src="currentUser.imageUrl"
              alt="User"
            />
            <img v-else src="@/assets/images/user.jpeg" alt="User" />
          </v-avatar>
        </v-btn>
      </template>
      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-layout>
                <img
                  class="menu-img"
                  v-if="currentUser.imageUrl"
                  :src="currentUser.imageUrl"
                  alt="User"
                />
                <img
                  class="menu-img"
                  v-else
                  src="@/assets/images/user.jpeg"
                  alt="User"
                />
                <v-flex class="ml-2 ">
                  <v-list-item-title class="text-capitalize">{{
                    currentUser.name
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    currentUser.email
                  }}</v-list-item-subtitle >
                   <v-list-item-subtitle class="font-weight-medium" v-if="currentUser.superAdmin"><small>Super Admin</small></v-list-item-subtitle>
                   <v-list-item-subtitle class="font-weight-medium" v-else-if="currentUser.isAdmin"><small>Admin</small></v-list-item-subtitle>
                   <v-list-item-subtitle class="font-weight-medium" v-else><small>User</small></v-list-item-subtitle>
                </v-flex>
              </v-layout>
            </v-list-item-content>
            <!-- <v-list-item-action>
              <v-btn small="small" color="pink" dark icon @click="editProfile">
                <v-icon small="small">edit</v-icon>
              </v-btn>
            </v-list-item-action> -->
          </v-list-item>
        </v-list>
        <v-card-text class="pb-0 pt-0">
          <span
            >Location Code:
            <span class="font-weight-bold">{{
              currentBusiness.code
            }}</span></span
          >
        </v-card-text>
        <v-list>
          <v-list-item @click="$router.push('/account')">
            <v-list-item-content>
              <v-list-item-title>My Account</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider> </v-divider>

        <v-card-actions>
          <ChangePassword :UserId="currentUser.id" />
          <v-spacer> </v-spacer>
          <v-btn color="primary" text="text" to="/logout">{{
            $t("buttons.logout")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import menuService from "../service.js";
import ChangePassword from "../../User/components/ChangePassword";

export default {
  name: "layout-menu",
  data() {
    return {
      rating: 0,
      fav: true,
      menu: false,
      message: false,
      hints: true,
    };
  },
  components: {
    ChangePassword,
  },
  computed: {
    ...mapGetters("global", ["currentUser", "currentBusiness"]),
  },
  methods: {
    editProfile() {
      this.$router.push(`/user/edit/${this.currentUser.userID}`);
    },
    getRating() {
      menuService.getRating(this.currentUser.userID).then((result) => {
        this.rating = result.data[0].average;
      });
    },
  },
};
</script>

<style scoped>
.menu-box {
  border: white solid 0.1em;
  padding: 7px;
  border-radius: 4px;
  cursor: pointer;
}
.menu-img {
  border-radius: 4px;
  width: 45px;
  height: 48px;
}
</style>

const state = {
  drawer: true,
  currentEmployee: {},
  authenticated: false,
  openSerialSearch: false,
  openRestockSerial: false,
  instantSearchIMEI: null,
}

const mutations = {
  DRAWER_TOGGLE() {
    state.drawer = !state.drawer
  },
  SET_OPEN_SERIAL_SEARCH(state, payload) {
    state.openSerialSearch = payload
    state.instantSearchIMEI = payload
  },
  RESET_INSTANT_SEARCH_IMEI(state) {
    state.instantSearchIMEI = null
  },
  SET_OPEN_RESTOCK_SERIAL(state, payload) {
    state.openRestockSerial = payload
  },
}

const actions = {
  drawerToggle({commit}) {
    commit('DRAWER_TOGGLE')
  },
  setOpenSerialSearch({commit}, payload) {
    // console.log('setOpenSerialSearch',payload)
    commit('SET_OPEN_SERIAL_SEARCH', payload)
  },
  resetInstantSearchIMEI({commit}) {
    commit('RESET_INSTANT_SEARCH_IMEI')
  },
  setOpenRestockSerial({commit}, payload) {
    commit('SET_OPEN_RESTOCK_SERIAL', payload)
  },
}

const getters = {
  getInstantSearchIMEI(state) {
    return state.instantSearchIMEI
  },
  getDrawer(state) {
    return state.drawer
  },
  getCurrentEmployee(state) {
    return state.currentEmployee
  },
  getOpenSerialSearch(state) {
    return state.openSerialSearch
  },
  getOpenRestockSerial(state) {
    return state.openRestockSerial
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}